// Here we will store shared styles for user app only (no encapsulated)

@import 'libs/ui/src/lib/assets/stylesheets/variables';
@import 'libs/ui/src/lib/assets/stylesheets/mixins';

// Paid indicator - blue $ sign
.paid-indicator-container {
  height: 24px;
  width: 24px;
  margin-left: 4px;
}

.paid-indicator-container--card {
  position: absolute;
  top: 12px;
  right: 12px;
}

// Taxes and totals
.confirm-purchase-details {
  @include font-text__x-small--regular;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.confirm-purchase-details__label {
  color: $kittch-grey-color;
}

.confirm-purchase-details__value {
  margin-left: 20px;
  color: $kittch-black-color;
}

.confirm-purchase-details--stream-title {
  margin-top: 32px;

  .confirm-purchase-details__label {
    @include font-text__medium--extra-heavy--supreme-family;
    color: $kittch-black-color;
    text-transform: uppercase;
  }

  .confirm-purchase-details__value {
    font-size: 16px;
    line-height: 16px;
  }
}

.confirm-purchase-details--stream-info {
  .confirm-purchase-details__label {
    font-size: 16px;
    line-height: 24px;
    color: $kittch-black-color;
  }

  .confirm-purchase-details__value {
    color: $kittch-grey-color;
  }
}

.confirm-purchase-details--discount span {
  color: $kittch-rich-blue-color;
}

.confirm-purchase-details--total span {
  font-size: 16px;
  line-height: 24px;
  color: $kittch-black-color;
  text-transform: uppercase;
}

.confirm-purchase-details--stripe-error {
  font-size: 16px;
  line-height: 24px;
  color: #F00;
}

.animated--fade-in {
  animation: fadeIn 1s infinite alternate;
}

@keyframes fadeIn {
  to { opacity: 0.3; }
}

.grecaptcha-badge {
  visibility: hidden;
}

// MODAL SDK
.modal-sdk-backdrop {
  background-color: rgb(10 10 10 / 86%);
}

.modal-sdk {
  // for top icon, which can be over of white bg
  --mdc-dialog-container-color: transparent;
}
