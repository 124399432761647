/* stylelint-disable */

.one-item-mainstage .swiper-button-prev,
.one-item-mainstage .swiper-button-next {
  display: none;
}

.swiper-wrapper {
  box-sizing: border-box;
}

.swiper-container {
  padding-bottom: 32px;
  width: 100%;
  z-index: 0;
}

.swiper-button-prev,
.swiper-button-next {
  background: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  border: 2px solid $kittch-black-color;
  justify-content: center;
  top: 40%;
  @media all and (max-width: 750px) {
    display: none;
  }
}

.swiper-button-prev:after,
.swiper-container-rtl
.swiper-button-next:after {
  content: url(./../images/svg/carousel-arrow.svg);
  width: fit-content;
  height: 24px;
  font-size: initial;
}

.swiper-button-next:after,
.swiper-container-rtl
.swiper-button-prev:after {
  content: url(./../images/svg/carousel-arrow.svg);
  transform: rotate(180deg);
  width: fit-content;
  height: 24px;
  font-size: initial;
}

.swiper-button-disabled {
  pointer-events: auto !important;
}

.swiper-pagination-bullet {
  background-color: #fff;
  border: 2px solid $kittch-black-color;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: $kittch-black-color;
  border: 2px solid $kittch-black-color;
}

.swiper-slide {
  height: 408px !important;
  cursor: grabbing;
  @media all and (max-width: 1440px) {
    height: calc(100vw * 0.284) !important;
  }

  @media all and (max-width: 1100px) and (min-width: 950px) {
    height: calc(100vw * 0.313) !important;
  }

  @media all and (max-width: 950px) and (min-width: 750px) {
    height: calc(100vw * 0.3755) !important;
  }

  @media all and (max-width: 750px) {
    height: calc(100vw * 0.53 + 286px) !important;
  }
}

.one-item-mainstage .swiper-slide {
  padding-right: 40px;
  @media all and (min-width: 1440px) {
    height: calc(100vw * 0.285) !important;
  }

  @media all and (max-width: 750px) {
    padding: 0;
  }
}
